import React from 'react';
import { Link } from 'react-router-dom';
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line

const LinkA = tw.a`underline text-secondary-100 hover:text-black`;

const translations = {
    pageInfo: {
        locationString: '/',
        title: 'Gunbot: Crypto Trading Bot for Automated Growth | Official Site',
        description: 'Grow your crypto income stream with Gunbot. Very capable, self-hosted, works with Binance, Bybit, Coinbase & more. Trading bot for for spot, futures, and DeFi.',
    },
    hero: {
        heading: "Gunbot. Community made crypto trading bot",
        description: (
            <>
                Privacy friendly crypto trading bot for 20+ supported exchanges. Works on Windows, Linux and macOS. API secrets stay on your own device.
                <br /><br />
                Use pre-tuned strategies that just work, or realize your own automation ideas.
                Gunbot is available as a subscription or lifetime license.
            </>
        ),
        notification: "Limited offer: free exchange slot on lifetime plans and upgrades ⚡",
        comparePlansButton: "Compare plans",
        newsAndBlogButton: "News and Blog",
        defiTradingBotButton: "DeFi trading bot",
        checkPerformanceButton: "Backtesting archive",
        officialPartnersText: "Official partners",
        partnersAltText: "Official Gunbot partners: Binance, Kucoin, Okex, HitBTC, Kraken Futures and more",
        recentNewsAndArticlesText: "Recent news and articles:",
        plansLink: '/plans/',
        defiLink: '/defi-trading-bot/',
        performanceLink: '/backtesting/',
    },
    showcase: {
        step3: {
            step: "step 3.",
            label: "Trade consistently",
            heading: "Your trading bot for crypto",
            description: <>Gunbot comes with great strategy settings out-of-the-box. <br />This chart shows trades with the stepGrid strategy.</>,
            alt1: "Screenshot showing great Gunbot trades",
        },
        step2: {
            step: "step 2.",
            label: "Choose Your Market",
            heading: "Find Hot Markets",
            description: <>Compare all markets in one single view. <br />It takes just seconds to activate a trading bot.</>,
            alt2: "Screenshot showing market comparison",
        },
        step1: {
            step: "step 1.",
            label: "Link Your Exchange",
            heading: "Seamless API Connection",
            description: <>There are no middlemen between your bot and the exchange. <br />It takes less than 5 minutes to set up.</>,
            alt3: "Screenshot showing how easy it is to connect API credentials in Gunbot",
        }
    },
    callToAction: {
        text: "Learn more about how automated crypto trading with Gunbot works.",
        primaryLinkText: "Bot trading features",
        primaryLinkUrl: "/features/",
        faqLinkTitle: "Frequently Asked Questions",
        faqLinkUrl: "https://www.gunbot.com/support/faq/",
    },
    gunbotAI: {
        subheading: "Unleash your trading potential instantly!",
        heading: "Create your own AI trading bot",
        description: `Imagine transforming your trading concepts into code within seconds. With Gunbot AI, you can turn your thoughts into reality with just a simple description. This powerful tool enables you to prototype new trading strategies effortlessly.
    
    Powered by the technology behind ChatGPT, Gunbot AI is designed for both crypto traders and automation experts. It understands all Gunbot-specific methods and data points, making it the ideal choice for developers and aspiring ones alike.
    
    It's essential to be cautious when first using machine-generated code. Always run it in a simulated bot instance first to ensure its reliability.
    
    Explore the endless possibilities of a crypto AI trading bot. Start creating your custom strategies today and revolutionize your trading experience!  
    Examples have not been edited.`,
        actionButtonText: "Discover more AI trading strategy examples",
        actionButtonURL: "https://www.gunbot.com/support/docs/custom-strategies/example-strategies/overview/",
    },
    how: {
        text: "See how easy it is to get started with Gunbot. It takes just a couple of minutes.",
        primaryLinkText: "How Gunbot works",
        primaryLinkUrl: "/how-gunbot-works/",
        primaryLinkTitle: "How to install Gunbot"
    },
    pricingModule: {
        heading: 'Get started with the top crypto trading bot',
        subheading: '',
        description: "Select billing cycle:",
        primaryButtonText: "I want this!",
        yearlyAdmonition: '-50%',
        lifetimeAdmonition: 'Best value',
        tabs: {
            Monthly: [],
            Annual: [],
            Lifetime: [],
        },
        plansDescription: <p className={"description"}>Fun fact: <br /><br />you can save 50% with yearly plans 😉</p>,
        plansDescriptionAnnual: <p className={"description"}>Fun fact: <br /><br />get a lifetime plan to save even more 😉</p>,
        plansDescriptionLifetime: <p className={"description"}>Fun fact: <br /><br />lifetime means pay once, use forever 😎</p>,
        showYearlyPlans: "Show Yearly Plans",
        showLifetimePlans: "Show Lifetime Plans",
        showMonthlyPlans: "Show Monthly Plans",
        joinGunbotCommunity: "Join Gunbot community",
        moreDetailsAbout: "More details about",
        planFeatures: [
            "Feature 1 description",
            "Feature 2 description",
        ],
    },
    features: {
        heading: "Why we think we've built the best crypto trading bot",
        cards: [
            {
                title: "Innovative trading strategies",
                description: "Choose from 20+ preset trading strategies with tested default settings. Using over 150 different parameters, you can customize every strategy to your trading style."
            },
            {
                title: "Unlimited trading pairs",
                description: "There are no limits to the amount of trading pairs Gunbot can actively trade. You decide if you want to launch a crypto trading bot on a sinbgle or one hundred markets simultaneously."
            },
            {
                title: "Additional trailing",
                description: "Use configurable price trailing options to get even better trades. This way trades are only placed when prices stopped moving in a certain direction."
            },
            {
                title: "Confirming indicators",
                description: "Use industry standard indicators to restrict trades to very specific market conditions. Choose between ADX, RSI, Stochastic, StochRSI, MFI, EMA, EMAspread & more!"
            },
            {
                title: "AutoConfig",
                description: "Don't try to beat a dynamic market with a static strategy. Gunbot is the only trading bot that offers rule based, dynamic config changes. Use built-in filter rules or code your own."
            },
            {
                title: "Visual targets",
                description: "Using the TradingView charting library the targets of a trading strategy are clearly visualized. This way you can easily tune your strategy without even leaving the chart."
            }
        ]
    },
    usps: {
        subheading: "Cutting edge trading automation",
        heading: "Powerful & easy trading bot for crypto",
        description: (
            <span>
                Upgrade your trading game with Gunbot, the automated trading bot for crypto enthusiasts and automation pros. Whether you're a veteran trader or a newbie, Gunbot lets you seamlessly execute your strategies and never miss a move.
            </span>
        ),
        imageAlt: "Easily create a custom bot trading strategy",
        features: [
            {
                title: "Total Control, No Cloud Dependency",
                description: (
                    <span>
                        Unlike other crypto trading bots, Gunbot runs directly on your computer. You don't have to rely on cloud services, ensuring your data's security and privacy. Compatible with Windows, macOS, Linux, and Raspberry Pi, Gunbot offers unmatched flexibility.
                    </span>
                ),
            },
            {
                title: "Plug and Play Bot Trading Strategies",
                description: (
                    <span>
                        Discover the power of plug and play with Gunbot. Profitable <LinkA href={"https://www.gunbot.com/support/guides/trading-logic-and-optimization/selecting-spot-strategies/"} title="How to select a Gunbot strategy">strategies</LinkA> like stepgridscalp come pre-tuned, allowing you to kickstart your trading performance instantly. Just turn on the bot and watch your profits grow.
                    </span>
                ),
            },
            {
                title: "Unlimited Possibilities for Power Users",
                description: (
                    <span>
                        Are you a power user craving customization? Gunbot has you covered. Enjoy <LinkA href={"https://www.gunbot.com/support/faq/multiple-bot-instances/"} title="Why run multiple gunbot instances">unlimited bot instances</LinkA>, craft your custom strategies, and automate configuration changes. The possibilities are endless – take your automated trading to the next level.
                    </span>
                ),
            },
            {
                title: "Free updates",
                description: "Gunbot offers free software updates, even for lifetime licenses. Pay only a one-time license fee and enjoy continuous improvements to enhance your trading strategies. We have subscriptions too, if you're not ready for lifetime yet.",
            }
        ],
        learnMoreLinkText: "Developer resources",
        learnMoreLinkURL: "/devcommunity/"
    },
    grid: {
        subheading: "Multi timeframe, easy to use spot and futures bots",
        heading: "Next-Level Grid and DCA Trading Bots",
        description: (
            <span>
                Gunbot's grid-like strategies redefine the way you trade. But what makes Gunbot stand out from traditional grid strategies? It's all about smart trading features:
                <br /><br />
                <strong>Super easy limit gridbots: </strong>Want a gridbot with limit orders, with rebalancing and custom grid steps? Gunbot has you covered.
                <strong>Built-in Trend Analysis:</strong> Gunbot's built-in trend analysis keeps you away from unfavorable market zones, ensuring you make smarter decisions.<br /><br />
                <strong>Dynamic Target Adjustments:</strong> Targets can automatically adapt to the recent market volatility, optimizing your trading results.<br /><br />
                <strong>Trailing for Better Fill Rates:</strong> Gunbot's trailing feature ensures you get the best possible fill rates on your orders. Even in grid strategies.<br /><br />
                The strategies are designed to produce exceptional trading results. Look at the chart images on this page – each green arrow represents a buy order, while each orange arrow represents a sell order. It's a visual representation of your success.
            </span>
        ),
        imageAlt: "Grid trading bot strategy with fully automated dca and profit targets",
    },
    testimonialsData: [
        {
            customerName: "Lebowski",
            quote:
                "Major love to the GB team. I started trading on DYDX using SGSFutures on Feb 26th. Started with $496 USD and I'm currently at $1,358 USD today in portfolio value. Running on 20 pairs. :)",
        },
        {
            customerName: "Đ₹ɄṂ₮Ḗ₡Ħ",
            quote:
                "i remember time I came to gunbot from haasonline, I have made maybe the rightest choice in my life",
        },
        {
            customerName: "Didier",
            quote:
                "Bought Gunbot in November, fumbled with Sim in December... Kicked off real trading last month... And after 1 month I made back the ultimate lifetime licence cost 2 times. Thanks for your great software @GuntharDeNiro  and to all those who helped make it stronger with new strategies and feedback for improving the systems over the years. This is the best bot I have seen in terms of control we have as a user.",
        },
        {
            customerName: "Daniel",
            quote:
                "Its making money out of the box just after editing balance settings, i have a plan to use it for scalping using ta turning it on or off depending on the market but so far is great."
        },
        {
            customerName: "ingo",
            quote:
                "it's a great product. I spent about a year working on my own bot in Python before discovering Gunbot. Gunbot turned out to be exactly what I envisioned for my 'dream bot,' but far more advanced and mature. That's why I consider it an excellent product that is worth every dollar. Thank you very much, and Merry Christmas!"
        },
        {
            customerName: "isayyas",
            quote:
                "BTW, your support is mind-blowing, but watching your baby bot grow for a few years now is whole another game. you guys are absolutely the best!"
        },
        {
            customerName: "Weston",
            quote:
                "Thanks for all of the input everyone. This is why I believe that Gunbot is still the best trading bot. Community matters, especially with cryptocurrency."
        },
        {
            customerName: "Hot Bots",
            quote:
                "My mind has been really spinning with the possibilities of DeFi Gunbot. Thanks @GuntharDeNiro and team for your amazing vision and cracking pace of development, which incorporates many serious contributions from the community. It's remarkable how far GB has come since I started using it in July."
        },
        {
            customerName: "Pascal",
            quote:
                "You have searched and I believe you have found. SGS is awesome. Thanks."
        },
        {
            customerName: "MG",
            quote:
                "As always great job, projects built and evolving in bear are here to stay"
        },
        {
            customerName: "puffncough",
            quote:
                "Having purchased Gunbot back in 2017, and using it on and off throughout the years. I am always impressed by the improvements that are made here internally and through the community. I rarely have to ask questions because I can find what I am looking for on the wiki or through the telegram channel.  You guys are amazing and thank you."
        },
        {
            customerName: "Dave",
            quote:
                "I knew manual trading pretty good but GB was the catalyst to learn how to codify that"
        },
        {
            customerName: "Steven",
            quote:
                "Well said!! been here since early 2018, been nothing but a great adventure, with great people. GO TEAM GUNBOT!!"
        },
        {
            customerName: "Nenad",
            quote:
                "StepGridHedge worked like a charm in this up/down, so far best from out of the box strategy, fire and forget and drink mojitos :)"
        },
        {
            customerName: "Donny",
            quote:
                "Special tribute to Gunthar's top gun for making trading as simple as abc - his strategies from 0 - 100 in 5 mins are unbeatable 💰😎"
        },
        {
            customerName: "Dave",
            quote:
                "StepGrid has become default to beat (and pretty hard)"
        },
        {
            customerName: "B B",
            quote:
                "After being quite suspicious I have quite good results with the spotgrid. Did not understand it properly in the first place - but now got the hang of it and it works quite well."
        },
        {
            customerName: "Jon",
            quote:
                "I look around and see so many talented people working on their own great strategies. its very heartening to be with people who want the same"
        },
        {
            customerName: "Murtis",
            quote:
                "I am very thankful for everyone here, fun little community! The difference since 5 years ago would be difficult to even explain to new users. There are some extremely talented/ingenious people here."
        },
        {
            customerName: "Beardednoir",
            quote:
                "Fantastic work on stepGrid, its another huge win for the GB community."
        },
        {
            customerName: "Stefan",
            quote:
                "Running so good out of the box i havent really looked into all switches and details"
        },
        {
            customerName: "Luis",
            quote:
                "Great job every one and thank you for all you help and hard work to make this bot better for all of us to make more money once again thank you all!!! and best wishes and luck to you all !! I am very thankful by far my best investment and thanks to gunbot i have learned so much about traiding im very thankful once again thank you all team and comunity!!"
        },
        {
            customerName: "Rayan",
            quote:
                "This is my Early days with Gunbot and i tried this as my second trade n got amazingly surprising results, this is like a magic when we compare it to other bots/ service."
        },
        {
            customerName: "Walton",
            quote:
                "Excellent support all the way from purchase to getting the bot up and running. 24 hour support via their telegram group and always friendly and willing to assist no matter how many times the same questions get asked. Great team."
        },
        {
            customerName: "EMC",
            quote:
                "I bought Gunbot back in the end of 2017, traded a bit, but then other things came in the way so i put it on ice. Recently I wrote a message in the contact form, and after like 20 minutes i got an email. They helped me get the bot back up running almost instantly. I did not expect such customer service after 3 years of buying a product like a crypto bot. Gunbot withstands the test of time !"
        },
        {
            customerName: "Mike",
            quote:
                "It’s extremely intimidating to allow a bot to control large sums of your money. Support had me go live in two days from payment to first trade. I’m sitting here writing this, watching my daughter play, bot streaming in background, and I finally feel like I can look away from my screen for more than 10 minutes. "
        },
        {
            customerName: "Ed",
            quote:
                " I did a substantial amount of research before purchasing automated trading software and I can confidently say I have zero regrets going with Gunbot. I have limited experience with such technical programs as I come from a manual trading background. The support network Gunbot provides is absolutely insane in terms of helpfulness and the response time is faster than what you would get from your wife! This product deserves a 5 star review ALL DAY LONG."
        },
        {
            customerName: "Nikita",
            quote:
                "Would definitely give Gunbot 5 stars. Because even for someone like me, who is a complete beginner to the world of trading bots, and will obviously struggle with the typical 'newbie' error codes associated with setting up a new software that you are not familiar with. Their fast responding support team and wiki were more than enough to solve theseissues. "
        },
        {
            customerName: "Siggen",
            quote:
                "Great peace of Software. Always evolving. New features added all the time without additional cost. Amazing community helping each other out bot with Trading and other stuff"
        },
        {
            customerName: "Eden",
            quote:
                "My friend and I been comparing using Eazybot Vs Gunbot. Gunbot definitely better, and more flexible in the long run. In addition, so far it is winning the comparisons we been doing."
        },
        {
            customerName: "Mike",
            quote:
                "Trailing stops are great and all but let’s be honest, on markets that run 24/7 more is required. Much more. GunBot was something I wished for a long time and my only regret is not doing it sooner. The bot itself has been awesome so far, and the support has been even better. Very fast responses, super knowledgeable, and friendly."
        },
        {
            customerName: "Nikita",
            quote:
                "Would definitely give Gunbot 5 stars. Because even for someone like me, who is a complete beginner to the world of trading bots, and will obviously struggle with the typical 'newbie' error codes associated with setting up a new software that you are not familiar with. Their fast responding support team and wiki were more than enough to solve theseissues."
        },
        {
            customerName: "Robert",
            quote:
                "Look, use stepgridscalp leave default and make money. [...] This community is fantastic and I will testify it is not a scam. Been a part of it since 2017. Making big fucking money with it. "
        }],
    why: {
        cards: [
            {
                title: "Your Trades, Private",
                description: "Gunbot is the leading trading bot for privacy-conscious users. Unlike many other bots, it never collects any data about your trades. Your transactions remain confidential, shared only between you and your exchange. With Gunbot, your privacy is always a top priority."
            },
            {
                title: "Manual and Bot Trading",
                description: <>
                    If you're looking to <LinkA href={"https://www.gunbot.com/support/faq/difference-automated-manual-trading-gunbot/"} title="How to use Gunbot in combination with manual trading">combine</LinkA> bot trading with manual trading on the same exchange account, Gunbot is the perfect companion.
                    <br /><br />
                    You can easily add to positions opened by Gunbot manually, giving you the flexibility needed for your trading strategy. Explore {<Link to="/use-cases/" title="Gunbot use cases">various ways</Link>} to use Gunbot.
                </>
            },
            {
                title: "Support at Every Step",
                description: <>
                    Gunbot offers powerful features and {<LinkA href="/support/" title="Gunbot support community">human support</LinkA>}. Whether you  live chat, email tickets, or more, There's also a wide range of docs and guides.
                </>
            }
        ],
        heading: "Why Many Traders Choose Gunbot",
        subheading: "",
        description: `Imagine bot crypto trading without constraints – Gunbot empowers you to seize profit opportunities around the clock. With Gunbot, your crypto trading never sleeps, ensuring you stay ahead in the fast-paced market.`
    },
    charts: [
            { id: 1, title: 'Use unique trading bot strategies', description: 'Automate trades with a range of built-in trading strategies. Or craft your own for a truly unique approach.' },
            { id: 2, title: 'Target multiple timeframes with ease', description: 'Trade with strategies that adapt to higher timeframe trends, making smarter decisions across all timeframes.' },
          ]
    ,
    // EN only
    related: {
        title: "Related topics",
        introText: "Explore our guides and articles to learn more about Gunbot.",
        articles: [
            { title: "What is the Gunbot Dev Community?", url: "https://www.gunbot.com/devcommunity/" },
            { title: "Quick start guide", url: "https://www.gunbot.com/support/guides/getting-started/gunbot-quickstart-guide/" },
            { title: "Are There Any Additional Costs Involved in Using Gunbot?", url: "https://www.gunbot.com/support/faq/additional-costs-gunbot/" },
            { title: "Spot trading bot strategy options", url: "https://docs.gunthy.org/guides/trading-logic-and-optimization/selecting-spot-strategies/" },
            { title: "How Frequently Does Gunbot Release Updates?", url: "https://www.gunbot.com/support/faq/gunbot-update-frequency/" },
        ],
    }
};


export default translations;