import React, { useEffect, useState } from 'react';
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { useLocation, Link } from 'react-router-dom'

import LogoImage from "images/logo-light.svg";
import { ReactComponent as MediumIcon } from "images/medium-icon.svg";
import { ReactComponent as YoutubeIcon } from "images/youtube-icon.svg";
import { ReactComponent as XIcon } from "images/x-icon.svg";
import { ReactComponent as OpenAiIcon } from "images/openai-icon.svg";
//import { ReactComponent as InstagramIcon } from "images/instagram-logo.svg";
import TextContent from "_content/Content.js";

const Container = tw.div`relative bg-primary-900 text-gray-100 -mx-8 -mb-8 px-8 -mt-24`;
const Content = tw.div`max-w-screen-xl mx-auto pt-16 pb-8`
const FiveColumns = tw.div`flex flex-wrap justify-between`;

const Column = tw.div`w-1/2 md:w-1/5 mb-8 md:mb-0 text-sm sm:text-base text-center md:text-left`;
const CompanyColumn = tw.div`text-center md:text-left mb-16 lg:mb-0 w-full lg:w-1/5`;

const ColumnHeading = tw.p`font-bold uppercase`;

const LinkList = tw.ul`mt-4 text-sm font-medium`;
const LinkListItem = tw.li`mt-3`;
const LinkA = tw.a`border-b-2 border-transparent hocus:text-gray-300 hocus:border-gray-100 pb-1 transition duration-300`;
const LinkTo = tw(Link)`border-b-2 border-transparent hocus:text-gray-300 hocus:border-gray-100 pb-1 transition duration-300`;

const LogoContainer = tw.div`flex items-center justify-center lg:justify-start`;
const LogoImg = tw.img`w-8`;
const LogoText = tw.p`ml-2 text-xl font-black`;

const CompanyAddress = tw.p`mt-4 max-w-xs font-medium text-sm mx-auto lg:mx-0 lg:mr-4 leading-loose text-center lg:text-left`;

const SocialLinksContainer = tw.div`mt-4 text-center lg:text-left`;
const SocialLink = styled.a`
  ${tw`cursor-pointer inline-block p-2 rounded-full bg-gray-100 text-gray-900 hover:bg-gray-500 transition duration-300 mr-4 last:mr-0`}
  svg {
    ${tw`w-4 h-4`}
  }
`;

const CopyrightAndCompanyInfoRow = tw.div`pb-0 text-sm font-normal flex flex-col sm:flex-row justify-between items-center`
const CopyrightNotice = tw.div``
const CompanyInfo = tw.div``

const Divider = tw.div`my-8 border-b-2 border-gray-800`



export default () => {

  const location = useLocation();
  const [urlPath, setUrlPath] = useState(location.pathname);

  useEffect(() => {
    setUrlPath(location.pathname);
  }, [location.pathname]);

  let usdtPairs = []
  let btcPairs = []
  const ports = Object.keys(TextContent.bots) || []
  if (urlPath.includes('performance')) {
    ports.forEach((port, index) => {
      TextContent.bots[port].forEach(pair => {
        const base = pair.split('-')[0]
        const quote = pair.split('-')[1]
        if (index === 0) {
          usdtPairs.push({
            port: port,
            base: base,
            quote: quote,
            pair: pair,
            exchange: 'binance'
          })
        }
        else {
          btcPairs.push({
            port: port,
            base: base,
            quote: quote,
            pair: pair,
            exchange: 'binance'
          })
        }
      });
    })
  }


  return (
    <Container>
      <Content>
        <FiveColumns>
          <CompanyColumn>
            <LogoContainer>
              <LogoImg src={LogoImage} alt="Gunbot logo" width="40px" height="40px" style={{ width: "40px", height: "40px" }} />
              <LogoText>Gunbot.com</LogoText>
            </LogoContainer>
            {/* 
            <CompanyAddress>
              
            </CompanyAddress>
            */}

            <SocialLinksContainer>
              <SocialLink href="https://medium.com/awesomegunbot" title="Articles about Gunbot on Medium.com" target="_blank" rel="noopener noreferrer">
                <MediumIcon />
              </SocialLink>
              <SocialLink href="https://www.youtube.com/@gunthardeniro" title="Gunbot on YouTube" target="_blank" rel="noopener noreferrer">
                <YoutubeIcon />
              </SocialLink>
              <SocialLink href="https://x.com/gunbot_official" title="Gunbot on X" target="_blank" rel="noopener noreferrer">
                <XIcon />
              </SocialLink>
              <SocialLink href="https://chatgpt.com/g/g-9Q96nWnrs-gunbot-guru" title="Gunbot Guru on ChatGPT" target="_blank" rel="noopener noreferrer">
                <OpenAiIcon />
              </SocialLink>
              {/* 
              <SocialLink href="https://instagram.com/gunbot_official" title="Gunbot on Instagram" target="_blank" rel="noopener noreferrer">
                <InstagramIcon />
              </SocialLink>
              */}
            </SocialLinksContainer>
          </CompanyColumn>
          {urlPath === "/how-gunbot-works/" &&
            <>
              <Column>
                <ColumnHeading>Trading</ColumnHeading>
                <LinkList>
                  <LinkListItem>
                    <LinkTo to="/trading/" title="Trading with Gunbot">Trading</LinkTo>
                  </LinkListItem>
                  <LinkListItem>
                    <LinkTo to="/day-trading/" title="Day trading with Gunbot">Day trading</LinkTo>
                  </LinkListItem>
                  <LinkListItem>
                    <LinkTo to="/trading/trading-bot/" title="Trading bot software">Trading bot software</LinkTo>
                  </LinkListItem>
                  <LinkListItem>
                    <LinkTo to="/trading/trading-software/" title="Trading software">Trading software</LinkTo>
                  </LinkListItem>
                  <LinkListItem>
                    <LinkTo to="/trading/trading-strategies/" title="Gunbot trading strategies">Trading strategies</LinkTo>
                  </LinkListItem>
                  <LinkListItem>
                    <LinkTo to="/trading/trading-system/" title="Trading system for crypto">Trading system</LinkTo>
                  </LinkListItem>
                  <LinkListItem>
                    <LinkTo to="/live-demo/" title="Live demo">Live demo</LinkTo>
                  </LinkListItem>
                </LinkList>
              </Column>
              <Column>
                <ColumnHeading>Cryptocurrency</ColumnHeading>
                <LinkList>
                  <LinkListItem>
                    <LinkTo to="/cryptocurrency/" title="Gunbot cryptocurrency trading bot">Cryptocurrency trading bot</LinkTo>
                  </LinkListItem>
                  <LinkListItem>
                    <LinkTo to="/cryptocurrency/investing/" title="Investing with Gunbot">Investing</LinkTo>
                  </LinkListItem>
                  <LinkListItem>
                    <LinkTo to="/cryptocurrency/portfolio/" title="Work your crypto portfolio with Gunbot">Portfolio</LinkTo>
                  </LinkListItem>
                  <LinkListItem>
                    <LinkTo to="/cryptocurrency/trading-for-beginners/" title="Trading for beginners">Trading for beginners</LinkTo>
                  </LinkListItem>
                  <LinkListItem>
                    <LinkTo to="/live-demo/" title="Live demo">Live demo</LinkTo>
                  </LinkListItem>
                </LinkList>
              </Column>
            </>
          }

          {urlPath === "/use-cases/" || urlPath === "/features/" &&

            <Column>
              <ColumnHeading>Exchanges</ColumnHeading>
              <LinkList>
                <LinkListItem>
                  <LinkTo to="/exchanges/" title="Supported exchanges">Supported exchanges</LinkTo>
                </LinkListItem>
                <LinkListItem>
                  <LinkTo to="/exchanges/binance-trading-bot/" title="Binance trading bot">Binance trading bot</LinkTo>
                </LinkListItem>
                <LinkListItem>
                  <LinkTo to="/exchanges/bitfinex-trading-bot/" title="Bitfinex trading bot">Bitfinex trading bot</LinkTo>
                </LinkListItem>
                <LinkListItem>
                  <LinkTo to="/exchanges/bitmex-trading-bot/" title="Bitmex trading bot">Bitmex trading bot</LinkTo>
                </LinkListItem>
                <LinkListItem>
                  <LinkTo to="/exchanges/bybit-trading-bot/" title="Bybit trading bot">Bybit trading bot</LinkTo>
                </LinkListItem>
                <LinkListItem>
                  <LinkTo to="/exchanges/bitget-trading-bot/" title="Bitget trading bot">Bitget trading bot</LinkTo>
                </LinkListItem>
                <LinkListItem>
                  <LinkTo to="/exchanges/bingx-trading-bot/" title="BingX trading bot">BingX  trading bot</LinkTo>
                </LinkListItem>
                <LinkListItem>
                  <LinkTo to="/exchanges/cryptocom-trading-bot/" title="Crypto.com trading bot">Crypto com trading bot</LinkTo>
                </LinkListItem>
                <LinkListItem>
                  <LinkTo to="/exchanges/coinbase-advanced-trading-bot/" title="Coinbase Advanced trading bot">Coinbase Advanced Trade Bot</LinkTo>
                </LinkListItem>
                <LinkListItem>
                  <LinkTo to="/exchanges/hitbtc-trading-bot/" title="HitBTC trading bot">HitBTC trading bot</LinkTo>
                </LinkListItem>
                <LinkListItem>
                  <LinkTo to="/exchanges/kraken-trading-bot/" title="Kraken trading bot">Kraken trading bot</LinkTo>
                </LinkListItem>
                <LinkListItem>
                  <LinkTo to="/exchanges/kucoin-trading-bot/" title="KuCoin trading bot">KuCoin trading bot</LinkTo>
                </LinkListItem>
                <LinkListItem>
                  <LinkTo to="/exchanges/poloniex-trading-bot/" title="Poloniex trading bot">Poloniex trading bot</LinkTo>
                </LinkListItem>
                <LinkListItem>
                  <LinkTo to="/exchanges/dydx-trading-bot/" title="dYdX trading bot">dYdX trading bot</LinkTo>
                </LinkListItem>
              </LinkList>
            </Column>

          }

          {urlPath === "/exchanges/" &&

            <Column>
              <ColumnHeading>Exchanges</ColumnHeading>
              <LinkList>
                <LinkListItem>
                  <LinkTo to="/exchanges/binance-trading-bot/" title="Binance trading bot">Binance trading bot</LinkTo>
                </LinkListItem>
                <LinkListItem>
                  <LinkTo to="/exchanges/binance-us-trading-bot/" title="Binance US trading bot">Binance US trading bot</LinkTo>
                </LinkListItem>
                <LinkListItem>
                  <LinkTo to="/exchanges/bitfinex-trading-bot/" title="Bitfinex trading bot">Bitfinex trading bot</LinkTo>
                </LinkListItem>
                <LinkListItem>
                  <LinkTo to="/exchanges/bitmex-trading-bot/" title="Bitmex trading bot">Bitmex trading bot</LinkTo>
                </LinkListItem>
                <LinkListItem>
                  <LinkTo to="/exchanges/bybit-trading-bot/" title="Bybit trading bot">Bybit trading bot</LinkTo>
                </LinkListItem>
                <LinkListItem>
                  <LinkTo to="/exchanges/bitstamp-trading-bot/" title="Bitstamp trading bot">Bitstamp trading bot</LinkTo>
                </LinkListItem>
                <LinkListItem>
                  <LinkTo to="/exchanges/cex-trading-bot/" title="CEX trading bot">Cex trading bot</LinkTo>
                </LinkListItem>
                <LinkListItem>
                  <LinkTo to="/exchanges/coinbase-advanced-trading-bot/" title="Coinbase Advanced trading bot">Coinbase Advanced Trade Bot</LinkTo>
                </LinkListItem>
                <LinkListItem>
                  <LinkTo to="/exchanges/hitbtc-trading-bot/" title="HitBTC trading bot">HitBTC trading bot</LinkTo>
                </LinkListItem>
                <LinkListItem>
                  <LinkTo to="/exchanges/huobi-trading-bot/" title="Huobi trading bot">Huobi trading bot</LinkTo>
                </LinkListItem>
                <LinkListItem>
                  <LinkTo to="/exchanges/kraken-trading-bot/" title="Kraken trading bot">Kraken trading bot</LinkTo>
                </LinkListItem>
                <LinkListItem>
                  <LinkTo to="/exchanges/kucoin-trading-bot/" title="KuCoin trading bot">KuCoin trading bot</LinkTo>
                </LinkListItem>
                <LinkListItem>
                  <LinkTo to="/exchanges/okx-trading-bot/" title="OKX trading bot">OKX trading bot</LinkTo>
                </LinkListItem>
                <LinkListItem>
                  <LinkTo to="/exchanges/poloniex-trading-bot/" title="Poloniex trading bot">Poloniex trading bot</LinkTo>
                </LinkListItem>
                <LinkListItem>
                  <LinkTo to="/exchanges/dydx-trading-bot/" title="dYdX trading bot">dYdX trading bot</LinkTo>
                </LinkListItem>
                <LinkListItem>
                  <LinkTo to="/exchanges/gateio-trading-bot/" title="Gate.IO trading bot">Gate.IO trading bot</LinkTo>
                </LinkListItem>
                <LinkListItem>
                  <LinkTo to="/exchanges/mexc-trading-bot/" title="MEXC trading bot">MEXC trading bot</LinkTo>
                </LinkListItem>
              </LinkList>
            </Column>

          }

          {urlPath === "/plans/" &&

            <Column>
              <ColumnHeading>Reviews</ColumnHeading>
              <LinkList>
                <LinkListItem>
                  <LinkA href="https://www.trustpilot.com/review/gunbot.com" title="Reviews on Trustpilot" target="_blank" rel="noopener noreferrer">Trustpilot</LinkA>
                </LinkListItem>

              </LinkList>
            </Column>

          }

          {urlPath.startsWith("/exchanges/") && (
            <Column>
              <ColumnHeading>API Configuration</ColumnHeading>
              <LinkList>
                {urlPath === "/exchanges/binance-trading-bot/" && (
                  <>
                    <LinkListItem>
                      <LinkA href="https://www.gunbot.com/support/guides/exchange-configuration/creating-api-keys/binance-api-key-creation/" title="Binance Spot API Key" rel="noopener noreferrer">Binance Spot API Key Configuration</LinkA>
                    </LinkListItem>
                    <LinkListItem>
                      <LinkA href="https://www.gunbot.com/support/guides/exchange-configuration/creating-api-keys/binance-futures-api-key-creation/" title="Binance Futures API Key" rel="noopener noreferrer">Binance Futures API Key Configuration</LinkA>
                    </LinkListItem>
                    <LinkListItem>
                      <LinkA href="https://www.gunbot.com/support/faq/gunbot-binance-automated-trading/" title="Binance Bot Trading Status" rel="noopener noreferrer">Binance Bot Trading Status</LinkA>
                    </LinkListItem>
                  </>
                )}
                {urlPath === "/exchanges/binance-us-trading-bot/" && (
                  <LinkListItem>
                    <LinkA href="https://www.gunbot.com/support/guides/exchange-configuration/creating-api-keys/binance-api-key-creation/" title="Binance US API Key" rel="noopener noreferrer">Binance US API Key Configuration</LinkA>
                  </LinkListItem>
                )}
                {urlPath === "/exchanges/bitfinex-trading-bot/" && (
                  <>
                    <LinkListItem>
                      <LinkA href="https://www.gunbot.com/support/guides/exchange-configuration/creating-api-keys/bitfinex-api-key-creation/" title="Bitfinex API Key" rel="noopener noreferrer">Bitfinex API Key Configuration</LinkA>
                    </LinkListItem>
                    <LinkListItem>
                      <LinkA href="https://www.gunbot.com/support/faq/bitfinex-automated-trading/" title="Bitfinex Bot Trading Status" rel="noopener noreferrer">Bitfinex Bot Trading Status</LinkA>
                    </LinkListItem>
                  </>
                )}
                {urlPath === "/exchanges/bitmex-trading-bot/" && (
                  <LinkListItem>
                    <LinkA href="https://www.gunbot.com/support/guides/exchange-configuration/creating-api-keys/bitmex-api-key-creation/" title="Bitmex API Key" rel="noopener noreferrer">Bitmex API Key Configuration</LinkA>
                  </LinkListItem>

                )}
                {urlPath === "/exchanges/bybit-trading-bot/" && (
                  <>
                    <LinkListItem>
                      <LinkA href="https://www.gunbot.com/support/guides/exchange-configuration/creating-api-keys/bybit-api-key-creation/" title="Bybit API Key" rel="noopener noreferrer">Bybit API Key Configuration</LinkA>
                    </LinkListItem>
                    <LinkListItem>
                      <LinkA href="https://www.gunbot.com/support/faq/automated-trading-bybit/" title="Bybit Bot Trading Status" rel="noopener noreferrer">Bybit Bot Trading Status</LinkA>
                    </LinkListItem>
                  </>
                )}
                {urlPath === "/exchanges/cex-trading-bot/" && (
                  <LinkListItem>
                    <LinkA href="https://www.gunbot.com/support/guides/exchange-configuration/creating-api-keys/cex-api-key-creation/" title="CEX API Key" rel="noopener noreferrer">CEX API Key Configuration</LinkA>
                  </LinkListItem>
                )}
                {urlPath === "/exchanges/coinbase-advanced-trading-bot/" && (
                  <>
                    <LinkListItem>
                      <LinkA href="https://www.gunbot.com/support/guides/exchange-configuration/creating-api-keys/coinbase-advanced-api-key-creation/" title="Coinbase Advanced Trade API Key" rel="noopener noreferrer">Coinbase Advanced Trade API Key Configuration</LinkA>
                    </LinkListItem>
                    <LinkListItem>
                      <LinkA href="https://www.gunbot.com/support/faq/automated-trading-coinbase/" title="Coinbase Bot Trading Status" rel="noopener noreferrer">Coinbase Bot Trading Status</LinkA>
                    </LinkListItem>
                  </>


                )}
                {urlPath === "/exchanges/huobi-trading-bot/" && (
                  <>
                    <LinkListItem>
                      <LinkA href="https://www.gunbot.com/support/guides/exchange-configuration/creating-api-keys/huobi-api-key-creation/" title="Huobi API Key" rel="noopener noreferrer">Huobi API Key Configuration</LinkA>
                    </LinkListItem>
                    <LinkListItem>
                      <LinkA href="https://www.gunbot.com/support/faq/automated-trading-huobi/" title="Huobi Bot Trading Status" rel="noopener noreferrer">Huobi Bot Trading Status</LinkA>
                    </LinkListItem>
                  </>


                )}
                {urlPath === "/exchanges/kraken-trading-bot/" && (
                  <>
                    <LinkListItem>
                      <LinkA href="https://www.gunbot.com/support/guides/exchange-configuration/creating-api-keys/kraken-api-key-creation/" title="Kraken API Key" rel="noopener noreferrer">Kraken API Key Configuration</LinkA>
                    </LinkListItem>
                    <LinkListItem>
                      <LinkA href="https://www.gunbot.com/support/faq/automated-trading-kraken/" title="Kraken Bot Trading Status" rel="noopener noreferrer">Kraken Bot Trading Status</LinkA>
                    </LinkListItem>
                  </>

                )}
                {urlPath === "/exchanges/okx-trading-bot/" && (
                  <>
                    <LinkListItem>
                      <LinkA href="https://www.gunbot.com/support/guides/exchange-configuration/creating-api-keys/okx-api-key-creation/" title="OKX API Key" rel="noopener noreferrer">OKX API Key Configuration</LinkA>
                    </LinkListItem>
                    <LinkListItem>
                      <LinkA href="https://www.gunbot.com/support/faq/automated-trading-okx/" title="OKX Bot Trading Status" rel="noopener noreferrer">OKX Bot Trading Status</LinkA>
                    </LinkListItem>
                  </>


                )}
                {urlPath === "/exchanges/poloniex-trading-bot/" && (
                  <>
                    <LinkListItem>
                      <LinkA href="https://www.gunbot.com/support/guides/exchange-configuration/creating-api-keys/poloniex-api-key-creation/" title="Poloniex API Key" rel="noopener noreferrer">Poloniex API Key Configuration</LinkA>
                    </LinkListItem>
                    <LinkListItem>
                      <LinkA href="https://www.gunbot.com/support/faq/gunbot-poloniex-support/" title="Poloniex Bot Trading Status" rel="noopener noreferrer">Poloniex Bot Trading Status</LinkA>
                    </LinkListItem>
                  </>
                )}
                {urlPath === "/exchanges/dydx-trading-bot/" && (
                  <>
                    <LinkListItem>
                      <LinkA href="https://www.gunbot.com/support/guides/exchange-configuration/creating-api-keys/dydx-v4-api-configuration/" title="dYdX v4 API Key" rel="noopener noreferrer">dYdX v4 API Key Configuration</LinkA>
                    </LinkListItem>
                    <LinkListItem>
                      <LinkA href="https://www.gunbot.com/support/guides/exchange-configuration/creating-api-keys/dydx-api-key-creation/" title="dYdX v3 API Key" rel="noopener noreferrer">dYdX v3 API Key Configuration</LinkA>
                    </LinkListItem>
                    <LinkListItem>
                      <LinkA href="https://www.gunbot.com/support/faq/automated-trading-dydx/" title="dYdX Bot Trading Status" rel="noopener noreferrer">dYdX Bot Trading Status</LinkA>
                    </LinkListItem>
                  </>
                )}
                {urlPath === "/exchanges/mexc-trading-bot/" && (
                  <LinkListItem>
                    <LinkA href="https://www.gunbot.com/support/guides/exchange-configuration/creating-api-keys/mexc-api-key-creation/" title="MEXC API Key" rel="noopener noreferrer">MEXC API Key Configuration</LinkA>
                  </LinkListItem>
                )}
                {urlPath === "/exchanges/gateio-trading-bot/" && (
                  <LinkListItem>
                    <LinkA href="https://www.gunbot.com/support/guides/exchange-configuration/creating-api-keys/gateio-api-key-creation/" title="Gate.IO API Key" rel="noopener noreferrer">Gate.IO API Key Configuration</LinkA>
                  </LinkListItem>
                )}
              </LinkList>
            </Column>
          )}

          {urlPath.includes('performance') &&
            <>
              <Column>
                <ColumnHeading>USDT performance</ColumnHeading>
                <LinkList>
                  {usdtPairs.map(item => {
                    return (<LinkListItem>
                      <LinkTo to={`/performance/${item.quote}${item.base}/`.toLowerCase()} title={`Gunbot ${item.quote}/${item.base} trading performance`}>{`${item.quote}/${item.base}`}</LinkTo>
                    </LinkListItem>)
                  })}
                </LinkList>
              </Column>
              <Column>
                <ColumnHeading>BTC performance</ColumnHeading>
                <LinkList>
                  {btcPairs.map(item => {
                    return (<LinkListItem>
                      <LinkTo to={`/performance/${item.quote}${item.base}/`.toLowerCase()} title={`Gunbot ${item.quote}/${item.base} trading performance`}>{`${item.quote}/${item.base}`}</LinkTo>
                    </LinkListItem>)
                  })}
                </LinkList>
              </Column>
              <Column>
                <ColumnHeading>Backtesting archive</ColumnHeading>
                <LinkList>
                  <LinkListItem>
                    <LinkA href="https://www.gunbot.com/backtesting/" title="Backtesting archive" target="_self" rel="noopener noreferrer">Backtesting archive</LinkA>
                  </LinkListItem>
                </LinkList>
              </Column>
            </>
          }

          {urlPath === "/plans/" &&
            <>
              <Column>
                <ColumnHeading>Gunbot plans</ColumnHeading>
                <LinkList>
                  <LinkListItem>
                    <LinkTo to="/plans/standard/" title="Gunbot Standard product details">Gunbot Standard</LinkTo>
                  </LinkListItem>
                  <LinkListItem>
                    <LinkTo to="/plans/pro/" title="Gunbot Pro product details">Gunbot Pro</LinkTo>
                  </LinkListItem>
                  <LinkListItem>
                    <LinkTo to="/plans/ultimate/" title="Gunbot Ultimate product details">Gunbot Ultimate</LinkTo>
                  </LinkListItem>
                </LinkList>
              </Column>
              <Column>
                <ColumnHeading>Upgrade Gunbot</ColumnHeading>
                <LinkList>
                  <LinkListItem>
                    <LinkTo to="/plans/upgrade/" title="Gunbot license upgrades overview">All upgrade options</LinkTo>
                  </LinkListItem>
                </LinkList>
              </Column>
            </>
          }

          {urlPath === "/plans/upgrade/" &&
            <>
              <Column>
                <ColumnHeading>Upgrade Gunbot</ColumnHeading>
                <LinkList>
                  <LinkListItem>
                    <LinkTo to="/plans/upgrade/one-to-standard/" title="Upgrade Gunbot One to Standard">One to Standard</LinkTo>
                  </LinkListItem>
                  <LinkListItem>
                    <LinkTo to="/plans/upgrade/one-to-pro/" title="Upgrade Gunbot One to Pro">One to Pro</LinkTo>
                  </LinkListItem>
                  <LinkListItem>
                    <LinkTo to="/plans/upgrade/one-to-ultimate/" title="Upgrade Gunbot One to Ultimate">One to Ultimate</LinkTo>
                  </LinkListItem>
                  <LinkListItem>
                    <LinkTo to="/plans/upgrade/starter-to-standard/" title="Upgrade Gunbot Starter to Standard">Starter to Standard</LinkTo>
                  </LinkListItem>
                  <LinkListItem>
                    <LinkTo to="/plans/upgrade/starter-to-pro/" title="Upgrade Gunbot Starter to Pro">Starter to Pro</LinkTo>
                  </LinkListItem>
                  <LinkListItem>
                    <LinkTo to="/plans/upgrade/starter-to-ultimate/" title="Upgrade Gunbot Starter to Ultimate">Starter to Ultimate</LinkTo>
                  </LinkListItem>
                  <LinkListItem>
                    <LinkTo to="/plans/upgrade/standard-to-pro/" title="Upgrade Gunbot Standard to Pro">Standard to Pro</LinkTo>
                  </LinkListItem>
                  <LinkListItem>
                    <LinkTo to="/plans/upgrade/standard-to-ultimate/" title="Upgrade Gunbot Standard to Ultimate">Standard to Ultimate</LinkTo>
                  </LinkListItem>
                  <LinkListItem>
                    <LinkTo to="/plans/upgrade/pro-to-ultimate/" title="Upgrade Gunbot Pro to Ultimate">Pro to Ultimate</LinkTo>
                  </LinkListItem>
                </LinkList>
              </Column>
            </>
          }

          <Column>
            <ColumnHeading>More information</ColumnHeading>
            <LinkList>
              <LinkListItem>
                <LinkA href="https://www.gunbot.com/support/faq/" title="Frequently Asked Questions">Frequently Asked Questions</LinkA>
              </LinkListItem>
              <LinkListItem>
                <LinkA href="https://www.gunbot.com/support/" title="Support resources">Support</LinkA>
              </LinkListItem>
              <LinkListItem>
                <LinkTo to="/contact/" title="Contact form">Contact</LinkTo>
              </LinkListItem>
              <LinkListItem>
                <LinkTo to="/resellers/" title="Reseller list">Resellers</LinkTo>
              </LinkListItem>
              <LinkListItem>
                <LinkA href="https://www.gunbot.com/blog/gunbot-monitor-for-power-users/" title="Gunbot Monitor">Gunbot Monitor</LinkA>
              </LinkListItem>
            </LinkList>
          </Column>
        </FiveColumns>
        <Divider />
        <CopyrightAndCompanyInfoRow>
          <CopyrightNotice>&copy; Copyright 2024, Gunbot.com</CopyrightNotice>
          <CompanyInfo>Gunbot is a product of Gunthar de Niro S.L.R. Gunbot.com is the official project website. </CompanyInfo>
        </CopyrightAndCompanyInfoRow>
      </Content>
    </Container>
  );
};
